.id-photo-capture {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
}

.camera-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85vw;
  height: 53.975vw; /* Aspect ratio of 1.5748 (ID-1 format) */
  max-width: 500px;
  max-height: 317px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
}

.frame-corner {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
}

.top-left {
  top: -2px;
  left: -2px;
  border-right: none;
  border-bottom: none;
}

.top-right {
  top: -2px;
  right: -2px;
  border-left: none;
  border-bottom: none;
}

.bottom-left {
  bottom: -2px;
  left: -2px;
  border-right: none;
  border-top: none;
}

.bottom-right {
  bottom: -2px;
  right: -2px;
  border-left: none;
  border-top: none;
}

.capture-button {
  position: absolute;
  bottom: 30px;
  padding: 15px 30px;
  font-size: 18px;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}
